import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `${AUTH_PREFIX_PATH}/reset-password`,
        component: React.lazy(() => import('views/auth-views/authentication/reset-password')),
    },
    
    
]


export const protectedRoutes = [
    /* change password */
    {
        key: 'change-password',
        path: `${APP_PREFIX_PATH}/dashboards/change-password`,
        component: React.lazy(() => import('views/app-views/dashboards/changepassword/changepassword')),
    },
    {
        key:'authDashboard',
        path: `${AUTH_PREFIX_PATH}/dashboards/home`,
        component: React.lazy(() => import('views/app-views/auth-dashboard/index')),
    },
    /***************DASHBOARD********DASHBOARD*******DASHBOARD**********/
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.healthtrack',
        path: `${APP_PREFIX_PATH}/dashboards/healthtrack`,
        component: React.lazy(() => import('views/app-views/dashboards/components/healthlist/healthlist')),
    },
    {
        key: 'dashboard.hrdefault',
        path: `${APP_PREFIX_PATH}/dashboards/hrdashboard`,
        component: React.lazy(() => import('views/app-views/dashboards/hrDashboard')),
    },
    {
        key: 'dashboard.teamleader',
        path: `${APP_PREFIX_PATH}/dashboards/teamleader`,
        component: React.lazy(() => import('views/app-views/dashboards/team leader dashboard/index')),
    },
    {
        key: 'dashboard.admin',
        path: `${APP_PREFIX_PATH}/dashboards/admin`,
        component: React.lazy(() => import('views/app-views/dashboards/admin Dashboard')),
    },
    {
        key: 'dashboard.management',
        path: `${APP_PREFIX_PATH}/dashboards/management`,
        component: React.lazy(() => import('views/app-views/dashboards/managerdashboard/index')),
    },

    {
        key: 'dashboard.department',
        path: `${APP_PREFIX_PATH}/dashboards/department`,
        component: React.lazy(() => import('views/app-views/dashboards/department')),
    },
    {
        key: 'dashboard.designation',
        path: `${APP_PREFIX_PATH}/dashboards/designation`,
        component: React.lazy(() => import('views/app-views/dashboards/department/designation')),
    },
    {
        key: 'dashboard.employeedocument',
        path: `${APP_PREFIX_PATH}/dashboards/Employeedocument`,
        component: React.lazy(() => import('views/app-views/dashboards/user/employeedocument/index')),
    },
    {
        key: 'dashboard.user',
        path: `${APP_PREFIX_PATH}/dashboards/user`,
        component: React.lazy(() => import('views/app-views/dashboards/user')),
    },
    {
        key: 'dashboard.adduser',
        path: `${APP_PREFIX_PATH}/dashboards/user-add`,
        component: React.lazy(() => import('views/app-views/dashboards/user/useradd')),
    },
    {
        key: 'dashboard.useredit',
        path: `${APP_PREFIX_PATH}/dashboards/user-edit/:employee_id`,
        component: React.lazy(() => import('views/app-views/dashboards/user/useredit')),
    },
    {
        key: 'dashboard.assignto',
        path: `${APP_PREFIX_PATH}/dashboards/assignto`,
        component: React.lazy(() => import('views/app-views/dashboards/components/assigntoemployee_manager/index')),
    },
    {
        key: 'dashboard.teamcreation',
        path: `${APP_PREFIX_PATH}/dashboards/team-creation`,
        component: React.lazy(() => import('views/app-views/dashboards/team creation/index')),
    },
    {
        key: 'dashboard.creation',
        path: `${APP_PREFIX_PATH}/dashboards/team-creation-form`,
        component: React.lazy(() => import('views/app-views/dashboards/team creation/teamcreationform')),
    },
    {
        key: 'dashboard.creationedit',
        path: `${APP_PREFIX_PATH}/dashboards/team-creation-form/:assign_id`,
        component: React.lazy(() => import('views/app-views/dashboards/team creation/teamcreationform')),
    },
    {
        key: 'dashboard.teammember-details',
        path: `${APP_PREFIX_PATH}/dashboards/team-memberdetails/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/team creation/teammemberdetails')),
    },
    {
        key: 'dashboard.teammemberdetails',
        path: `${APP_PREFIX_PATH}/dashboards/team-memberdetails`,
        component: React.lazy(() => import('views/app-views/dashboards/userteam/index')),
    },
    {
        key: 'dashboard.userview',
        path: `${APP_PREFIX_PATH}/dashboards/user-view/:employee_id`,
        component: React.lazy(() => import('views/app-views/dashboards/user/userview')),
    },
    {
        key: 'dashboard.profile',
        path: `${APP_PREFIX_PATH}/dashboards/user-view`,
        component: React.lazy(() => import('views/app-views/dashboards/userprofile/index')),
    },
    {
        key: 'dashboards-salarydetail',
        path: `${APP_PREFIX_PATH}/dashboards/user-salarydetails`,
        component: React.lazy(() => import('views/app-views/dashboards/components/usersalarydetails/index'))
    },
    {
        key: 'dashboard.report',
        path: `${APP_PREFIX_PATH}/dashboards/user-report`,
        component: React.lazy(() => import('views/app-views/dashboards/userreport/index')),
    },
    {
        key: 'dashboard.bulkupload',
        path: `${APP_PREFIX_PATH}/bulkupload`,
        component: React.lazy(() => import('views/app-views/dashboards/user/uploadbulk/index')),
    },
    {
        key: 'dashboard.document',
        path: `${APP_PREFIX_PATH}/dashboards/documents`,
        component: React.lazy(() => import('views/app-views/dashboards/department/document')),
    },

    {
        key: 'dashboard.assetss',
        path: `${APP_PREFIX_PATH}/dashboards/asset-type`,
        component: React.lazy(() => import('views/app-views/dashboards/department/asset')),
    },
    {
        key: 'dashboard.assets',
        path: `${APP_PREFIX_PATH}/dashboards/user-asset`,
        component: React.lazy(() => import('views/app-views/dashboards/user-asset/index')),
    },
    {
        key: 'dashboard.earingStatements',
        path: `${APP_PREFIX_PATH}/dashboards/earning-statement`,
        component: React.lazy(() => import('views/app-views/dashboards/department/earningstatement')),
    },
    {
        key: 'dashboard.earingStatementsform',
        path: `${APP_PREFIX_PATH}/dashboards/earning-statement-form/:employee_id`,
        component: React.lazy(() => import('views/app-views/dashboards/user/earningSalaryStatement/earningStatementForm')),
    },
    {
        key: 'dashboard.earingStatement',
        path: `${APP_PREFIX_PATH}/dashboards/add-earning-statement/:employee_id`,
        component: React.lazy(() => import('views/app-views/dashboards/user/earningSalaryStatement/index')),
    },
    {
        key: 'dashboard.ticket',
        path: `${APP_PREFIX_PATH}/dashboards/ticket`,
        component: React.lazy(() => import('views/app-views/dashboards/department/ticket')),
    },
    {
        key: 'dashboard.tickets',
        path: `${APP_PREFIX_PATH}/dashboards/tickets`,
        component: React.lazy(() => import('views/app-views/dashboards/user-ticket')),
    },
    {
        key: 'dashboard.expensestype',
        path: `${APP_PREFIX_PATH}/dashboards/expensestype`,
        component: React.lazy(() => import('views/app-views/dashboards/department/expense')),
    },
    {
        key: 'dashboard.expense',
        path: `${APP_PREFIX_PATH}/dashboards/expense`,
        component: React.lazy(() => import('views/app-views/dashboards/expense')),
    },
    {
        key: 'dashboard.attendance',
        path: `${APP_PREFIX_PATH}/dashboards/attendance`,
        component: React.lazy(() => import('views/app-views/dashboards/user-attendance')),
    },
    {
        key: 'dashboard.employeeattendance',
        path: `${APP_PREFIX_PATH}/dashboards/attendance/:employee_id`,
        component: React.lazy(() => import('views/app-views/dashboards/user-attendance/employeeattendancecalendar')),
    },
    {
        key: 'dashboard.bankdetail',
        path: `${APP_PREFIX_PATH}/dashboards/bankdetail`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/user/bankdetail')),
    },
    {
        key: 'dashboard.asset',
        path: `${APP_PREFIX_PATH}/dashboards/Asset`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/user/asset')),
    },
    {
        key: 'dashboard.leaves',
        path: `${APP_PREFIX_PATH}/dashboards/leave`,
        component: React.lazy(() => import('views/app-views/dashboards/department/leave')),
    },
    {
        key: 'dashboards-ALLleaves',
        path: `${APP_PREFIX_PATH}/dashboards/all-user-leave`,
        component: React.lazy(() => import('views/app-views/dashboards/user-leave/AllEmployeeLeave')),
    },
    {
        key: 'dashboard.employeeleaves',
        path: `${APP_PREFIX_PATH}/dashboards/employee-leaves`,
        component: React.lazy(() => import('views/app-views/dashboards/user-leave/userleavelist')),
    },
    {
        key: 'dashboard.payslip',
        path: `${APP_PREFIX_PATH}/dashboards/payslip`,
        component: React.lazy(() => import('views/app-views/dashboards/payslip')),
    },
    {
        key: 'dashboard.role',
        path: `${APP_PREFIX_PATH}/dashboards/role`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/role')),
    },
    {
        key: 'dashboard.rolepermission',
        path: `${APP_PREFIX_PATH}/dashboards/roles/permission/:role_id`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/role/permission')),
    },
    {
        key: 'dashboard.permissions',
        path: `${APP_PREFIX_PATH}/dashboards/permissions`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/permission')),
    },
    {
        key: 'dashboard.weekdayspermission',
        path: `${APP_PREFIX_PATH}/weekdayspermission`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/weekdayspermissions/index')),
    },
    
]