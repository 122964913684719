import fetch from 'auth/FetchMasterInterceptor'
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
const userService = {}
userService.excelLink = function (data) {
  return fetch({
    url: '/excel-link',
    method: 'get',
    data:data
  })
}
userService.getUser = function (data) {
  return fetch({
    url: '/employee/list',
    method: 'post',
    data:data
  })
}
userService.addUser = function (data) {
  return fetch({
    url: '/employee/create',
    method: 'post',
    data:data
  })
}
userService.editUser = function (data) {
  return fetch({
    url: '/employee/update',
    method: 'post',
    data:data
  })
}
userService.assignUser = function (data) {
  return fetch({
    url: '/employee/assign',
    method: 'post',
    data:data
  })
}
userService.deleteUser = function (data) {
  return fetch({
    url: '/employee/delete',
    method: 'post',
    data:data
  })
}
userService.showUser = function (data) {
  return fetch({
    url: '/employee/show',
    method: 'post',
    data:data
  })
}
userService.addUserExperience = function (data) {
  return fetch({
    url: '/user-experience/create',
    method: 'post',
    data:data
  })
}
userService.ListUserEducation = function (data) {
  return fetch({
    url: '/education-details/list',
    method: 'post',
    data:data
  })
}
userService.DeleteUserEducation = function (data) {
  return fetch({
    url: '/education-details/delete',
    method: 'post',
    data:data
  })
}
userService.addUserEducation = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/education-details/create`, data, config);
}
userService.editUserEducation = function (data) {
  return fetch({
    url: '/education-details/update',
    method: 'post',
    data:data
  })
}
userService.ListUserExperience = function (data) {
  return fetch({
    url: '/user-experience/list',
    method: 'post',
    data:data
  })
}
userService.ShowUserExperience = function (data) {
  return fetch({
    url: '/user-experience/show',
    method: 'post',
    data:data
  })
}
userService.EditUserExperience = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/user-experience/update`, data, config);
}
userService.AddUserExperience = function (data) {
 const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/user-experience/create`, data, config);
}
userService.DeleteUserExperience = function (data) {
  return fetch({
    url: '/user-experience/delete',
    method: 'post',
    data:data
  })
 }
userService.editUserDocument = function (data) {
  return fetch({
    url: '/user-document/update',
    method: 'post',
    data:data
  })
}
userService.addUserDocument = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization":`Bearer ${jwtToken}`
    },
  };
  return axios
    .post(`${API_BASE_URL}/user-document/create`, data, config);
}
userService.deleteUserDocument = function (data) {
  return fetch({
    url: '/user-document/delete',
    method: 'post',
    data:data
  })
}
userService.showUsersalaryrate = function (data) {
  return fetch({
    url: '/type-rate/list',
    method: 'post',
    data:data
  })
}
userService.deleteearningstates=function (data) {
  return fetch({
    url: '/type-rate/delete',
    method: 'post',
    data:data
  })
}
userService.editUsersalaryrate = function (data) {
  return fetch({
    url: '/type-rate/update',
    method: 'post',
    data:data
  })
}
userService.addUsersalaryrate = function (data) {
  return fetch({
    url: '/type-rate/storeUpdate',
    method: 'post',
    data:data
  })
}
userService.getAttendees = function (data) {
  return fetch({
    url: '/health/list',
    method: 'post',
    data:data
  })
}
userService.showAttendees = function (data) {
  return fetch({
    url: '/health/show',
    method: 'post',
    data:data
  })
}
userService.getFamilyDetails = function (data) {
  return fetch({
    url: '/family-details/list',
    method: 'post',
    data:data
  })
}
userService.addFamilyDetails = function (data) {
  return fetch({
    url: '/family-details/create',
    method: 'post',
    data:data
  })
}
userService.deleteFamilyDetails = function (data) {
  return fetch({
    url: '/family-details/delete',
    method: 'post',
    data:data
  })
}
userService.healthtrack = function (data) {
  return fetch({
    url: '/attendance',
    method: 'post',
    data:data
  })
}
userService.tlList = function (data) {
  return fetch({
    url: '/employee/team/list',
    method: 'post',
    data:data
  })
}
userService.DeletetlList = function (data) {
  return fetch({
    url: '/employee/deleteEmployeeAssignment',
    method: 'post',
    data:data
  })
}
userService.employeedataList = function (data) {
  return fetch({
    url: '/employee/search',
    method: 'post',
    data:data
  })
}
userService.managerdataList = function (data) {
  return fetch({
    url: '/manager/list',
    method: 'post',
    data:data
  })
}
userService.memberdataList = function (data) {
  return fetch({
    url: '/manager/getEmployeesUnderManager',
    method: 'post',
    data:data
  })
}
userService.teamassign = function (data) {
  return fetch({
    url: '/employee/assign',
    method: 'post',
    data:data
  })
}
userService.showassignemployee = function (data) {
  return fetch({
    url: '/manager/show',
    method: 'post',
    data:data
  })
}
export default userService;
